@mixin easeTransitions {
    -moz-transition: ease all .5s;
    -webkit-transition: ease all .5s;
    -moz-transition: ease all .5s;
    -o-transition: ease all .5s;
    transition: ease all .5s;
}

.footerWrapper{
    // position: fixed;
    position: relative;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0px auto;
    bottom: 0;
    align-items: center;

    width: 100vw;
    opacity: .34;

    @include easeTransitions;

    @media (max-width:320px) { 
        /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
        font-size: 14px;
    }
    @media (max-width:480px) { 
        /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
        font-size: 14px;
    }
    @media (max-width:600px) { 
        /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
        grid-template: 1fr / 1fr;
        grid-gap: 5px;
        padding: 0px;
        align-self: center;
    }
    @media only screen 
    and (max-width: 800px) 
    and (orientation : landscape) {
        font-size: 14px;
    }

    &__copyright{
        margin: 0px auto;
        
        @media(max-width: 800px)and (orientation : landscape) {
            justify-self: center;
            font-size: 14px;
        }
    }

    &__madeBy{
        justify-self: end;
        margin: 0px auto;

        #designedBy{
            cursor: pointer;
            font-weight: 700;
        }

        @media(max-width: 800px)and (orientation : landscape) {
            justify-self: center;
            font-size: 14px;

        }
    } 
}