.NotFound{
    
    img{
    position: fixed; 
    float: left;
    object-fit: cover;
    width: 100vw; 
    height: 100vh;
    z-index: -2;
    }
}
.headerTitle{
  
  &__Header{
    position: absolute;
    width: 100%;
  }
}
.NFtext{
    background-color:rgba(35, 31, 32, 0.64);;

    display: grid;
    grid-template-rows: max-content max-content;
    justify-content: center;
    align-content: center;
    height: 100vh;
    
    color: var(--white);
    text-decoration: none;

    &__text{
        display: grid;
        // grid-template-rows: 50px 90px auto;
        row-gap: 20px;

        h1{
            font-size: 54px;
        }
        p{
            display: grid;
            align-items: center;
            line-height: 150%;
            margin-bottom: 30px;
        }

        a{
            display: grid;
            justify-self: start;
            justify-content: center;
            margin: 0;
            
        }
    }

    a:link {
        text-decoration: none;
    }

    &__button{
      // width: max-content;
      display: flex;
    }

    @media(max-width: 600px) {
      padding: 30px;
    }
}


/////////////////////////////////
//Default Button
///////////////////////////////
.btn {
  
    cursor: pointer;
    position: relative;
    font-size: 1em;
    font-weight: 400;
    line-height: 45px;
    // max-width: 180px; 
    max-width: max-content; 
    text-decoration: none;
    text-transform: uppercase;
    
    @media(max-width: 600px) {
      font-size: 16px;
      font-weight: 400;
    }
    
    &:hover { text-decoration: none; }

    h4{
      font-weight: 400;
      padding-left: 15px;
      margin: 0;
    }
    
  }
  
  /////////////////////////////////
  //Button
  ///////////////////////////////
  .btn-1 {
    background: none;
    font-weight: 500;
    
    svg {
      height: 45px;
      position: absolute;
      width: 150px;
    }
    
    rect {
      fill: none;
      width: 140px;
      stroke: #fff;
      stroke-width: 2;
      stroke-dasharray: 422, 0;

      -moz-transition: all 0.35s linear;
      -webkit-transition: all 0.35s linear;
      -moz-transition: all 0.35s linear;
      -o-transition: all 0.35s linear;
      transition: all 0.35s linear;

      @media only screen 
      and (max-width: 600px){
        width: 115px;
      }
      
    }
  }
  
  .btn-1:hover {
    background: none;
    letter-spacing: 1px;
    font-weight: 900;
    font-size: 16px;
  
    @media(min-width: 600px) {
        h4{
        font-size: 16px;
        font-weight: 900;
      }
    }
    
    rect {
      stroke-width: 5;
      stroke-dasharray: 15, 310;
      stroke-dashoffset: 48;

      -moz-transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
      -moz-transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
      -o-transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
  
  @keyframes morphing {
    0%, 100%{
      d:path('M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4 c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6	c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8	C48.9,198.6,57.8,191,51,171.3z')
    }
    50%{
      d:path('M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45	c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2	c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7	c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z');
    }
    
  }