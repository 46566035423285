/////////////////////////////////
//Set Items
/////////////////////////////////
$white: #fff;
@mixin opacity {
  background-color:#231F20 !important;
  opacity: 0.64 !important;
  -khtml-opacity: 0.64 !important;
  -moz-opacity: 0.64 !important;
  filter: alpha(opacity=64) !important;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=64)" !important;
}

@mixin easeTransitions {
  -moz-transition: ease all .5s;
  -webkit-transition: ease all .5s;
  -moz-transition: ease all .5s;
  -o-transition: ease all .5s;
  transition: ease all .5s;
}

/////////////////////////////////
//Overlay of the dark grey gradient
/////////////////////////////////
.overlay{
  position: fixed;
  @include opacity;
  top: 0px;
  width: 100vw;
  height: 100vh;
  //remove white border from blur background image
  transform: scale(1.5); 
}

.containEverything{
  display: grid;
  grid-template-rows: .5fr 4fr 1fr .5fr;
  height: 100vh;

  @media screen 
  and (max-width:480px) 
  and (orientation : portrait){ 
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
    grid-template-rows: .5fr 2fr 1fr .5fr;
    height: 100vh;
  }
  @media screen 
  and (max-width:900px) 
  and (orientation : landscape){ 
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
    grid-template-rows: .25fr 1fr .5fr .25fr;
    height: 100vh;
  }
}

.card{
    perspective: 1000px;
    align-self: center;
    @include easeTransitions;

    @media screen 
    and (max-height: 800px) {
      margin-top: 0px;
      margin-bottom: 5px;
    }

    @media screen 
    and (max-width: 360px) 
    and (max-height: 640px) {
      // width: 100%;
      // height: 100%;
      margin-top: 0px;
      margin-bottom: 5px;
    }
    @media only screen 
    and (max-width: 480px) 
    and (max-height: 844px) 
    and (-webkit-device-pixel-ratio: 3)
    and (orientation : portrait) {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  
    @media screen 
    and (max-height: 360px) 
    and (orientation : landscape) {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  
    @media screen 
    and (min-height: 361px) 
    and (max-height: 480px) 
    and (orientation : landscape) {
      margin-top: 0px;
      margin-bottom: 20px;
    }

    @media screen 
    and (min-height: 481px) 
    and (max-height: 768px) 
    and (orientation : landscape) {
      margin-top: 20px;
      margin-bottom: 30px;
    }

  }
  /////////////////////////////////
  //Button Wrapper
  ///////////////////////////////
  .containerBtn { 
    align-self: center;
    justify-self: center;
    
    @media (max-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        align-self: baseline;
    }
  }

  /////////////////////////////////
  //Default Button
  ///////////////////////////////
  .homebtn {
    position: relative;
    color: var(--white);
    justify-self: center;
    // padding: 0 20px;
    max-width: 180px; 
    width: 100%; 
    padding: 20px 20px;
    border: none;
    cursor: pointer;
    
    &:hover { text-decoration: none; }

    @media only screen 
    and (max-width: 800px)
    and (orientation : landscape) {
      position: relative;
      max-width: 160px; 
    } 
  }

  /////////////////////////////////
  //Button
  ///////////////////////////////
  .homebtn-1 {
    background: none;
    font-weight: 500;
    font-size: 1em;
    max-width: 210px; 

    svg {
      position: absolute;
      width: 100%; 
      height: 55px;
      left: 0;
      top: 0; 
    }
    
    rect {
      fill: none;
      stroke: #fff;
      stroke-width: 2;
      stroke-dasharray: 422, 0;

      @include easeTransitions;
    }
  }

  .homebtn-1:hover {
    background: none;
    font-weight: 900;
    letter-spacing: 1px;
    max-width: 210px; 

    @media(min-width: 600px) {
      font-size: 16px;
      font-size: 16px;
    }
    
    rect {
      stroke-width: 10;
      stroke-dasharray: 15, 300;
      stroke-dashoffset: 48;
      transition: all 1.35s cubic-bezier(0.19, 1, 0.2, 1);
    }
  }

  @keyframes morphing {
    0%, 100%{
      d:path('M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4 c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6	c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8	C48.9,198.6,57.8,191,51,171.3z')
    }
    50%{
      d:path('M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45	c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2	c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7	c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z');
    }
    
  }



/////////////////////////////////
//
///////////////////////////////

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/////////////////////////////////
//Custom Shape Button
///////////////////////////////
.randomSelect{
  display: grid;
  margin-top: 30px;

  position: absolute;

  //Position items in the centre
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 0);

  &__blurShadow{
    filter: url(#blur-shadow);
  }
}

svg path{
  fill:rgb(250, 28, 28);
  d:path('M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4 c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6	c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8	C48.9,198.6,57.8,191,51,171.3z');
  animation: morphing 3s infinite;
  cursor: pointer;
}