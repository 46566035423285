.headerAll{
  position: relative;
  display: grid;
  text-align: center;
  align-items: center;
  height: 70px;
  margin: 25px 5%;
  z-index: 1;
 
  @media only screen 
  and (max-width: 480px)
  and (orientation : portrait) {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  @media only screen 
  and (max-width: 900px) 
  and (orientation : landscape) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
    
  &__headerTitle{

    h1{
      justify-self: center;
      align-self: center;
      text-transform: uppercase;
      cursor: pointer;

      @media only screen 
      and (max-width: 800px)
      and (orientation : portrait) {
        font-size: 1.5em;
      }

      @media only screen 
      and (max-width: 900px)
      and (orientation : landscape) {
        font-size: 1.5em;
      }
    }
  }

  &__headerMenu{
    position: absolute;
    display: grid;
    height: 70px;
    top: 0;
    right: 0;
    margin: 0 5%;

    img{
      align-self: center;
      transform: scale(1.5); 
      cursor: pointer;
    }

    #openMenu{
      padding: 0;
      color: var(--white);
      font-weight: 400;
      letter-spacing: 0.14em;
      background: none;
      border-style: none;

    }
  }
}

