@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
  font-family: "Inter",'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  --white:#F7F7FF;
  --yellow:#F6C700;
  --black:#231F20;
  --red:#DA4167;
  --babyblue:#BDD5EA;
  
  color: var(--white);
  font-size: 1em;
  
  margin: 0;
  padding: 0;
}

h1{
  font-size: 2.125em;
  font-family: "Inter";
  font-weight: 600;
  margin: 0;
  padding: 0;
}

h2{
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
  margin: 0;
  padding: 0;
}

h3{
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

h4{
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  margin: 0;
  padding: 0;
}


p{
  font-family: "Roboto";
  font-size: 1em;
  font-size: 1em;
  margin: 0;
  padding: 0;
}

a:link {
  text-decoration: none;
  color: var(--white);
}

a:visited { 
  color: var(--white);
}

ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}